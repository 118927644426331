<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Requisição de Pessoal</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalEdit"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>
      <ApplicantInfo
        :applicant-name="applicantName"
        :applicant-sector="applicantSector"
        :applicant-store="applicantStore"
      />
      <Separator
        label="Justificativa"
        class="mt-6"
      />
      <div
        v-if="profile === 'Recursos Humanos'"
        class="d-flex w-100 justify-end mt-3"
      >
        <v-tooltip
          v-if="!isEditable"
          top
          color="info"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="info"
              v-bind="attrs"
              @click="handleActiveInputs"
              v-on="on"
            >
              <v-icon
                size="25"
                color="info"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>

          <span>Editar</span>
        </v-tooltip>
        <v-tooltip
          v-else
          top
          color="success"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="success"
              v-bind="attrs"
              :disabled="isSavingData"
              @click="handleSave"
              v-on="on"
            >
              <v-icon
                v-if="!isSavingData"
                size="25"
                color="success"
              >
                {{ icons.mdiContentSave }}
              </v-icon>
              <v-progress-circular
                v-else
                color="success"
                indeterminate
              >
              </v-progress-circular>
            </v-btn>
          </template>

          <span>Salvar</span>
        </v-tooltip>
      </div>
      <div
        class="line"
        :class="{'mt-2': profile === 'Recursos Humanos'}"
      >
        <v-autocomplete
          v-model="typeContractValue"
          class=""
          :items="listTypeContract"
          label="Tipo de Contrato"
          outlined
          :disabled="!isEditable"
          dense
        />

        <v-autocomplete
          v-model="reasonRequestValue"
          class=""
          :items="listReasonRequest"
          label="Motivo da Requisição"
          outlined
          :disabled="!isEditable"
          dense
        />
      </div>

      <v-autocomplete
        v-if="reasonRequestValue === 'SUBSTITUIÇÃO'"
        v-model="replacedEmployeeValue"
        class=""
        :items="listEmployees"
        label="Funcionário Substituído"
        item-text="name"
        item-value="id"
        outlined
        dense
        :disabled="substituteEmployee"
      />

      <v-textarea
        v-model="reasonDescriptionValue"
        label="Descrição do motivo"
        :disabled="!isEditable"
        outlined
      />
      <Separator
        label="Dados da vaga"
        :disabled="!isEditable"
        class="mt-6"
      />

      <v-autocomplete
        v-model="functionValue.id"
        :items="listFunctions"
        :rules="[rules.required]"
        label="Função"
        class="mt-8"
        item-text="occupation.name"
        item-value="occupation.id"
        outlined
        disabled
        dense
      />

      <v-text-field
        v-model="sectorValue.name"
        class=""
        label="Setor"
        item-text="name"
        item-value="id"
        outlined
        dense
        disabled
      />

      <div
        v-if="sectorValueid === 'ff5f9668-d5b7-42f5-ae2b-5fe34854a2a0' || sectorValueid === 'd911e3ed-a989-479e-a8e9-36debec9df70' || sectorValueid === '6ce1e394-daf0-4294-827c-4d05eb4b4f91' || sectorValueid === '38b9b784-053f-4587-b8c6-346baad569dd' || sectorValueid === 'efabfa1d-da9a-482d-baa5-7af8500f5101'"
        style="display: flex; align-items: center; margin-top: 20px; margin-bottom: 25px; height: 30px;"
      >
        <div>
          <p>A função terá a responsabilidade de vender acessórios?</p>

          <v-radio-group
            v-model="radioValue"
            row
            disabled
          >
            <v-radio
              label="Sim"
              value="SIM"
              color="#9C43FA"
            />

            <v-radio
              label="Não"
              value="NÃO"
              color="#9C43FA"
            />
          </v-radio-group>
        </div>
      </div>

      <div class="line mt-0">
        <v-text-field
          v-model="ageValue"
          class=""
          label="Idade"
          outlined
          :disabled="!isEditable"
          dense
        />

        <v-autocomplete
          v-model="genderValue"
          class=""
          :items="listGenders"
          item-text="name"
          item-value="id"
          label="Sexo"
          outlined
          dense
          :disabled="!isEditable"
        />
        <v-autocomplete
          v-model="educationLevelValue"
          class=""
          :items="listEducation"
          item-text="name"
          item-value="id"
          label="Nível de escolaridade"
          outlined
          :disabled="!isEditable"
          dense
        />
      </div>

      <v-textarea
        v-model="requirementsVacancyValue"
        label="Requisitos da Vaga"
        :disabled="!isEditable"
        outlined
      />

      <v-textarea
        v-model="mainActivitiesValue"
        label="Principais Atividades"
        :disabled="!isEditable"
        outlined
      />

      <FieldsetApprovalTL :data="data" />

      <div
        v-if="profile === 'Recursos Humanos'"
        class="container-center"
      >
        <v-btn
          color="error"
          style="gap: 10px;"
          @click="handleOpenModalCancel"
        >
          <div
            class="d-flex align-center"
            style=" gap: 10px;"
          >
            <span>
              CANCELAR
            </span>
            <v-icon>{{ icons.mdiCancel }}</v-icon>
          </div>
        </v-btn>

        <v-btn
          color="success"
          style="gap: 10px;"
          @click="handleOpenModalSendRD"
        >
          <div
            class="d-flex align-center"
            style=" gap: 10px;"
          >
            <span>
              ENVIAR PARA RD
            </span>
            <v-icon>{{ icons.mdiShare }}</v-icon>
          </div>
        </v-btn>
      </div>
    </v-card>

    <v-dialog
      v-model="showModalCancel"
      width="540px"
    >
      <ModalCancel
        :data="dataCancel"
        @close="showModalCancel = false"
        @updatedTable="handleUpdatedTable"
      ></ModalCancel>
    </v-dialog>

    <v-dialog
      v-model="showModalSendRD"
      width="540px"
    >
      <ModalSendRD
        :data="dataModalSendRD"
        :close-modal-edit="handleCloseModalEditData"
        @close="showModalSendRD = false"
        @updatedTable="handleUpdatedTable"
      ></ModalSendRD>
    </v-dialog>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import {
  mdiAccountOutline,
  mdiCancel,
  mdiChatProcessingOutline,
  mdiClose,
  mdiContentSave,
  mdiDoorClosed, mdiOfficeBuildingMarkerOutline,
  mdiPencil,
  mdiShare,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import ModalCancel from './ModalCancel.vue'

import ModalSendRD from './ModalSendRD.vue'
import ApplicantInfo from './components/ApplicantInfo.vue'
import FieldsetApprovalTL from './components/FieldsetApprovalTL.vue'

export default {
  components: {
    ModalCancel,
    ModalSendRD,
    ApplicantInfo,
    FieldsetApprovalTL,
    Separator,
  },
  mixins: [formatters],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    closeModalEdit: { type: Function, default: () => {} },
    profile: { type: String, default: '' },
  },

  data() {
    return {
      dataModalSendRD: {},
      loadingSubmitRequest: false,

      isEditable: false,

      listGenders: ['MASCULINO', 'FEMININO', 'NÃO BINÁRIO'],
      listEducation: ['FUNDAMENTAL', 'MÉDIO', 'SUPERIOR', 'SUPERIOR INCOMPLETO'],

      typeContractValue: '',
      reasonRequestValue: '',
      replacedEmployeeValue: '',
      reasonDescriptionValue: '',
      functionValue: {},
      sectorValue: '',
      ageValue: '',
      educationLevelValue: '',
      genderValue: '',
      sectorValueid: '',
      radioValue: '',
      requirementsVacancyValue: '',
      mainActivitiesValue: '',
      applicantName: '',
      applicantStore: '',
      applicantSector: '',

      listEmployees: [],
      listFunctions: [],
      listSectors: [],

      skeleton: true,
      isSavingData: false,
      showModalCancel: false,
      showModalSendRD: false,
      icons: {
        mdiClose, mdiDoorClosed, mdiOfficeBuildingMarkerOutline, mdiAccountOutline, mdiCancel, mdiShare, mdiChatProcessingOutline, mdiPencil, mdiContentSave,
      },
      listTypeContract: [
        'CONTRATO EFETIVO',
        'CONTRATO TEMPORÁRIO',
        'CONTRATO POR TEMPO DETERMINADO',
        'ESTAGIÁRIO',
        'APRENDIZ',
      ],
      listReasonRequest: [
        'AUMENTO DE QUADRO',
        'PROMOÇÃO',
        'SUBSTITUIÇÃO',
      ],
      ReasonRequestValue: '',
      dataCancel: {},
    }
  },

  computed: {
    substituteEmployee() {
      return (this.reasonRequestValue !== 'SUBSTITUIÇÃO' || !this.isEditable)
    },
  },
  async created() {
    this.dataCancel = this.data
    if (!this.data?.id) {
      this.handleCloseModalEdit()

      return
    }

    this.handleGetData()

    const listEmployees = localStorageSlim.get('listEmployees', { decrypt: true })

    this.listEmployees = listEmployees

    axiosIns.get('api/v1/records/occupation/index').then(res => {
      this.listFunctions = res.data.data
    })

    axiosIns.get('api/v1/records/sector/index').then(res => {
      this.listSectors = res.data.data
    })
  },

  methods: {
    async handleGetData() {
      try {
        const response = await axiosIns.get(`api/v1/personnel_request/employee_request/edit/${this.data.id}`)
        await axiosIns.get(`/api/v1/personnel_request/employee_request_status_history/get_leaders/${this.data.id}`)
        this.typeContractValue = response.data.data.contract_types
        this.reasonRequestValue = response.data.data.request_reason
        this.replacedEmployeeValue = response.data.data?.substitute_user?.id
        this.reasonDescriptionValue = response.data.data.request_reason_description
        this.functionValue = { name: response.data.data?.occupation?.name, id: response.data.data?.occupation?.id }
        this.sectorValue = { name: response.data.data?.sector?.name, id: response.data.data?.sector?.id }
        this.ageValue = response.data.data.age
        this.educationLevelValue = response.data.data.education_level
        this.genderValue = response.data.data.sex
        this.requirementsVacancyValue = response.data.data.vacancy_requirements
        this.mainActivitiesValue = response.data.data.main_activities
        this.applicantName = response.data.data.creator.name
        this.applicantStore = response.data.data.creator.company.city
        this.applicantSector = response.data.data.creator.occupation_sector.sector.name
        this.sectorValueid = response.data.data.sector_id
        this.radioValue = response.data.data.is_for_acessories ? 'SIM' : 'NÃO'
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          showConfirmButton: false,
          text: 'Erro ao buscar os dados na API!',
          timer: 3000,
        })
      } finally {
        this.skeleton = false
      }
    },
    async handleSave() {
      if (
        this.reasonRequestValue === 'SUBSTITUIÇÃO'
        && (!this.replacedEmployeeValue
        || (typeof this.replacedEmployeeValue === 'object'
        && 'name' in this.replacedEmployeeValue
        && this.replacedEmployeeValue.name === undefined))
      ) {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          showConfirmButton: false,
          text: 'Você deve selecionar um funcionário para substituir!',
          timer: 3000,
        })

        return
      }

      const body = {
        contract_types: this.typeContractValue,
        request_reason: this.reasonRequestValue,
        substitute_user_id: this.replacedEmployeeValue || null,
        request_reason_description: this.reasonDescriptionValue,
        occupation_id: this.functionValue.id,
        sector_id: this.sectorValue.id,
        age: Number(this.ageValue),
        sex: this.genderValue,
        education_level: this.educationLevelValue,
        vacancy_requirements: this.requirementsVacancyValue,
        main_activities: this.mainActivitiesValue,
        status: 'AGUARDANDO RH',
      }
      this.isSavingData = true
      try {
        await axiosIns.put(`/api/v1/personnel_request/employee_request/update/${this.data.id}`, body)
        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          showConfirmButton: false,
          text: 'Alteração feita com sucesso!',
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro!',
          showConfirmButton: false,
          text: 'Erro ao eviar os dados para a API!',
          timer: 3000,
        })
      } finally {
        this.isSavingData = false
        this.isEditable = !this.isEditable
        this.handleGetData()
      }
    },
    handleActiveInputs() {
      this.isEditable = !this.isEditable
    },
    handleCloseModalEdit() {
      this.closeModal()
    },

    handleUpdatedTable() {
      this.updatedTable()
    },

    handleCloseModalEditData() {
      this.closeModalEdit()
    },

    handleOpenModalCancel() {
      this.showModalCancel = true
    },

    async handleOpenModalSendRD() {
      if (this.isEditable) {
        this.$swal({
          icon: 'info',
          title: 'Atenção!',
          showConfirmButton: true,
          text: 'Você deve salvar os dados antes de enviar para o líder!',
          timer: 5000,
        })

        return
      }
      const body = {
        contract_types: this.typeContractValue,
        request_reason: this.reasonRequestValue,
        occupation_id: this.functionValue,
        sector_id: this.sectorValue,
        age: Number(this.ageValue),
        sex: this.genderValue,
        education_level: this.educationLevelValue,
        vacancy_requirements: this.requirementsVacancyValue,
        main_activities: this.mainActivitiesValue,
        status: 'AGUARDANDO RD',
        substitute_user_id: this.replacedEmployeeValue,
        request_reason_description: this.reasonDescriptionValue,
        id: this.data.id,
      }

      this.dataModalSendRD = body

      this.showModalSendRD = true
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.isExpanded{
  border: 1px solid #FF4C51;
  margin: 10px 10px;
  padding: 0px;
  border-radius: 5px;
  transition: ease;
  transition-duration: 0.4s;
}
.isExpandedInside{

  margin: 0px 0px !important;
  padding-left: 18px !important;
}
.isNotExpanded{
  transition: ease;
  transition-duration: 0.2s;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}

.container-center{
  display: flex;
  justify-content: space-between
}
</style>
