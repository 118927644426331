<template>
  <div>
    <v-card class="px-9 py-9">
      <div class="head-modal">
        <div></div>

        <span>Enviar para RD</span>

        <v-icon
          medium
          class="me-2"
          @click="handleCloseModalSendRD"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </div>

      <div class="py-4">
        <span>👋 Olá, antes de enviar para RD, preencha os campos abaixo!</span>
      </div>
      <Separator
        label="Dados da Função"
        class="my-6"
      />

      <v-form ref="form">
        <v-text-field
          v-model="data.occupation_id.name"
          class="mt-6"
          item-text="name"
          item-value="id"
          label="Função"
          outlined
          dense
          disabled
        />

        <v-text-field
          v-model="data.sector_id.name"
          class=""
          item-text="name"
          item-value="id"
          label="Setor"
          outlined
          dense
          disabled
        />

        <div class="line mt-0">
          <v-text-field
            v-model="formattedSalary"
            type="text"
            class=""
            label="Salário"
            outlined
            dense
            :rules="[rules.required]"
          />

          <v-autocomplete
            v-model="className"
            class=""
            :items="listClass"
            label="Classe"
            outlined
            dense
            :rules="[rules.required]"
          />
          <v-autocomplete
            v-model="level"
            :items="listLevels"
            class=""
            label="Nível"
            outlined
            dense
            :rules="[rules.required]"
          />

          <v-text-field
            v-model="atr"
            class=""
            label="ATR"
            outlined
            dense
          />
        </div>

        <v-textarea
          v-model="comments"
          class="mt-0"
          label="Observações"
          outlined
        />
      </v-form>

      <div class="container-center">
        <v-btn
          color="success"
          class="ml-4"
          @click="handleSendRD"
        >
          <div
            v-if="!loadingSendRD"
            class="d-flex align-center"
            style="gap: 5px;"
          >
            <span>ENVIAR PARA RD</span>
            <v-icon>{{ icons.mdiShare }}</v-icon>
          </div>

          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<script>
import Separator from '@/components/Separator.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiClose, mdiShare } from '@mdi/js'

export default {
  components: {
    Separator,
  },

  mixins: [formatters],

  props: {
    data: {
      type: [String, Object],
      required: true,
    },
    closeModalEdit: { type: Function, default: () => {} },
  },

  data() {
    return {
      loadingSendRD: false,
      icons: {
        mdiClose, mdiShare,
      },
      listClass: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
      listLevels: ['1', '2', '3', '4', '5', '6', '7', '8'],
      salary: '',
      className: '',
      level: '',
      atr: '',
      comments: '',
    }
  },

  computed: {
    formattedSalary: {
      get() {
        const value = this.salary.toString().replace(/\D/g, '')
        const formatted = (Number(value) / 100).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })

        return formatted === 'R$ 0,00' ? '' : formatted
      },
      set(newValue) {
        const value = newValue.replace(/\D/g, '')
        this.salary = (Number(value) / 100).toFixed(2)
      },
    },
  },
  watch: {
    // salary(val) {
    //   return this.formatBrazilianCurrency(val)
    // },
  },

  created() {
  },

  methods: {
    handleCloseModalSendRD() {
      this.closeModal()
    },

    handleOpenModalCancelConfirm() {
      this.showModalCancelConfirm = true
    },

    parseCurrencyToNumber(value) {
      return Number(value.replace(/[^0-9,-]+/g, '').replace(',', '.'))
    },

    async handleSendRD() {
      const formIsValid = this.$refs.form.validate()

      if (!formIsValid) return

      const numericSalary = this.parseCurrencyToNumber(this.formattedSalary)

      this.loadingSendRD = true

      const body = {
        contract_types: this.data.contract_types || null,
        request_reason: this.data.request_reason || null,
        occupation_id: this.data.occupation_id.id || null,
        sector_id: this.data.sector_id.id || null,
        age: Number(this.data.age) || 0,
        sex: this.data.sex || null,
        education_level: this.data.education_level || null,
        vacancy_requirements: this.data.vacancy_requirements || null,
        main_activities: this.data.main_activities || null,
        status: 'AGUARDANDO RD' || null,
        substitute_user_id: this.data.substitute_user_id?.id || null,
        request_reason_description: this.data.request_reason_description || null,
        salary_value: numericSalary || null,
        class: this.className || null,
        level_value: this.level || null,
        human_resource_observation: this.comments || null,
        atr_number: this.atr || null,
      }

      try {
        await axiosIns.put(`api/v1/personnel_request/employee_request/update/${this.data.id}`, body)

        this.$swal({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Enviado para o RD com sucesso!',
          showConfirmButton: false,
          timer: 3000,
        })
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: 'Erro ao enviar dados!',
          showConfirmButton: false,
          text: error,
          timer: 3000,
        })
      } finally {
        this.loadingSendRD = false

        this.updatedTable()
        this.closeModal()
        this.closeModalEdit()
      }
    },
  },
}
</script>
<style scoped>
.head-modal{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.container-center{
  display: flex;
  justify-content: center;
}

.line{
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
}
</style>
